<template>
	<ion-text color="danger" v-bind="$attrs">
		<small v-if="$_.get(field, 'required.$invalid')">{{ $translate($_.get(field, 'required.$message')) }}</small>
		<small v-if="$_.get(field, 'cnpj.$invalid')">CNPJ inválido</small>
		<small v-if="$_.get(field, 'email.$invalid')">E-mail inválido</small>
<!--		<small v-if="$_.get(field, 'minLength.$invalid')">-->
<!--			<translate>Este campo deve possuir no mínimo {{ $_.get(field, '$params.minLength.min') }} caracteres.</translate>-->
<!--		</small>-->
	</ion-text>
</template>

<script>
	//Componentes Ionic
	import {IonText} from "@ionic/vue";

	export default {
		name: "ValidationError",

		props: {
			field: Object
		},

		components: {
			IonText
		}
	}
</script>

<style scoped>

</style>
