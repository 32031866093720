<template>
	<ion-select
		@ionChange="$emit('change', $event.target.value)"
		:cancel-text="$translate('Cancel')"
		:value="value"
		v-bind="$attrs"
	>
		<slot></slot>
	</ion-select>
</template>

<script>
	//Componentes Ionic
	import {IonSelect} from "@ionic/vue";

	export default {
		name: "CustomSelect",

		model: {
			prop: 'value',
			event: 'change'
		},

		props: {
			value: [String]
		},

		components: {
			IonSelect
		}
	}
</script>
